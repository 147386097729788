import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const useStyles = makeStyles(styles);

export default function dzienJednosci2019() {
  const classes = useStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/animator-5/szkola_animatora_5.png'} alt={'szkola animatora 5 odwołana'} style={{ padding: '30px', margin: '5vw auto', width: '100%' }} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
